import {
  setCatchHandler,
  setDefaultHandler,
  registerRoute,
} from 'workbox-routing';
import { ExpirationPlugin } from 'workbox-expiration';
import {
  CacheFirst,
  StaleWhileRevalidate,
  NetworkOnly,
} from 'workbox-strategies';

// comment out for debug
self.__WB_DISABLE_DEV_LOGS = true;

registerRoute(
  ({ request }) =>
    request.destination === 'script' ||
    request.destination === 'style' ||
    request.destination === 'document',
  new StaleWhileRevalidate({
    cacheName: 'static-resources',
  }),
);

registerRoute(
  ({ request }) => request.destination === 'image',
  new CacheFirst({
    cacheName: 'images',
    plugins: [
      new ExpirationPlugin({
        maxEntries: 60,
        maxAgeSeconds: 30 * 24 * 60 * 60, // 30 Days
      }),
    ],
  }),
);

setDefaultHandler(new NetworkOnly());

setCatchHandler(() => Response.error());
